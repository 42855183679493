import { createStore } from 'vuex';

export default createStore({
  state: {
    participants: [],
  },
  getters: {
  },
  mutations: {
    ADD_PARTICIPANT(state, participant) {
      state.participants.push(participant);
    },
  },
  actions: {
    addToParticipants(context, participant) {
      context.commit('ADD_PARTICIPANT', participant);
    },
  },
  modules: {
  },
});
