<template>
<div class="normal-text-container">
  <h1>Impressum</h1>
  <h2>Redaktionelle Verantwortlichkeit</h2>
  <p>
    Alumni der Informatik Dortmund e.V. <br/>
    Vereinigung der Ehemaligen und Freunde der Fakultät für Informatik der TU Dortmund<br/>
    Eingetragen im Vereinsregister Dortmund: VR 5921
  </p>

  <h2>Vorsitzender</h2>
  <p>
    <strong>Hans Decker</strong> <br/>
    c/o Dekanat Informatik <br/>
    Otto-Hahn-Str. 14 <br/>
    D-44221 Dortmund
  </p>
  <p>Telefon: +49 231 755-2208</p>
  <p>E-Mail: hans.decker@udo.edu</p>

  <h2>Technische Verantwortlichkeit</h2>
  <p>
    <strong>Till Barz</strong> <br/>
    E-Mail: till.barz@udo.edu
  </p>
</div>
</template>

<script>
export default {
  name: "ImpressumView"
}
</script>

<style scoped>
.normal-text-container {
  max-width: 700px;
  margin: 5px auto;
}
</style>
