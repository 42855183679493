<template>
  <div class="supporter animate__fadeIn" :style="styling">
    <n-image :src="supporter.image" :width="200 + supporter.relevance * -1.7" class="profile-image"/>
    <h3>{{supporter.name}}</h3>
  </div>
</template>

<script>
import {NImage} from "naive-ui";

export default {
  name: 'SupporterMap',
  props: {
    supporter: {
      type: Object,
      required: true,
    },
    animationDuration: {
      type: String,
      default: "5s",
    },
  },
  data() {
    return {
      colors: [
        '#ffffff',
        '#cccccc',
        '#dddddd',
        '#eeeeee',
        '#bbbbbb',
        '#aaaaaa',
      ]
    }
  },
  computed: {
    styling() {
      return {
        color: this.colors[Math.floor(Math.random()*this.colors.length)],
        '--x-pos': this.supporter.x + "px",
        '--y-pos': this.supporter.y + "px",
        '--animation-duration': this.animationDuration,
        '--transition-delay': this.supporter.transitionDelay + "s",
        '--font-size': this.supporter.relevance + "px",
      }
    }
  },
  components: {
    NImage,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .supporter {
    display: inline-block;
    opacity: 0;
    margin: 30px;
    //animation-duration: var(--animation-duration);
    //animation-delay: var(--transition-delay);
    font-size: var(--font-size);

    animation: fadeIn forwards var(--animation-duration) var(--transition-delay);
    -webkit-animation: fadeIn forwards var(--animation-duration) var(--transition-delay);
    -moz-animation: fadeIn forwards var(--animation-duration) var(--transition-delay);
    -o-animation: fadeIn forwards var(--animation-duration) var(--transition-delay);
    -ms-animation: fadeIn forwards var(--animation-duration) var(--transition-delay);

    h1, h2, h3, h4 {
      margin: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .profile-image {
    border-radius: 25%;
  }
</style>
