<template>
  <div class="supporterMap">
    <h1 v-if="presenterStyle">Alle Unterstützer</h1>
    <n-divider v-if="!presenterStyle">
      <h3>Alle Unterstützer</h3>
    </n-divider>
    <n-space horizontal style="display: flex; justify-content: center; align-content: center"
             v-if="!presenterStyle">
      <n-button @click="$router.push('besupporter')">Klicke hier um das Projekt zu Unterstützen</n-button>
    </n-space >
    <Supporter v-for="participant in localParticipants" :key="participant.name"
               :supporter="participant" animationDuration="5s"/>
  </div>
</template>

<script>
import Supporter from './Supporter.vue';
import {NButton, NSpace, NDivider} from "naive-ui";
import {getSupporters} from '@/services/supporter';

export default {
  name: "SupporterMap",
  props: {
    presenterStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localParticipants: [],
    };
  },
  methods: {
    fetchSupporters() {
      getSupporters().then(data => {
        var delay = 0;
        this.localParticipants = data.map((p) => {
          const randomRelevance = Math.floor(Math.random() * 30 + 10);
          delay = delay + 0.3;
          return {
            name: p.firstname + " " + p.lastname,
            image: p.image,
            relevance: randomRelevance,
            transitionDelay: delay,
          };
        }).sort((a, b) => 0.5 - Math.random());
      }).catch(err => console.error("Error"));
    },
  },
  created() {
    this.fetchSupporters();
    this.timer = setInterval(this.fetchSupporters, 30000);
  },
  components: {
    Supporter,
    NButton,
    NSpace,
    NDivider,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.supporterMap {
  text-align: center;
}

h1 {
  font-size: 50px;
}
</style>
