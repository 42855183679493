<template>
  <n-divider>
    <h2>Außenansicht</h2>
  </n-divider>
  <n-carousel autoplay show-arrow>
    <img v-for="name in outsideImages" :src="name" :key="name" class="carousel-img"/>
  </n-carousel>

  <n-divider>
    <h2>360° Bilder</h2>
  </n-divider>
  <div class="panorama-container">
    <vue-pannellum src="360images/DJI_0075.jpg" style="height: 500px" :mouse-zoom="false"
                   auto-rotate show-zoom min-pitch="20" max-pitch="170"/>
    <vue-pannellum src="360images/room12_1.jpg" style="height: 500px" :mouse-zoom="false"
                   auto-rotate show-zoom min-pitch="20" max-pitch="170"/>
    <vue-pannellum src="360images/room12_3.jpg" style="height: 500px" :mouse-zoom="false"
                   auto-rotate show-zoom min-pitch="20" max-pitch="170"/>
  </div>
</template>

<script>
import {NCarousel, NDivider} from "naive-ui";
import VuePannellum from 'vue-pannellum';

export default {
  name: 'ImagesView',
  data() {
    return {
      outsideImages: [
        "outside/DJI_0056.jpg",
        "outside/DJI_0058.jpg",
        "outside/DJI_0059.jpg",
        "outside/DJI_0071.jpg",
        "outside/DJI_0076.jpg",
        "outside/DJI_0079.jpg",
        "outside/DJI_0081.jpg",
      ]
    }
  },
  components: {
    NCarousel,
    NDivider,
    VuePannellum,
  }
};
</script>

<style scoped lang="scss">
.carousel-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.panorama-container {
  margin: 0px 20px;

  div {
    margin: 20px 0px;
  }
}
</style>
