<template>
  <div class="container">
    <h3 class="title pre-title">Hier entsteht das ...</h3>
    <h1 class="title">Deutsche Internet Museum</h1>
    <p>Tragen Sie sich hier ein, wenn Sie das Projekt unterstützen möchten</p>
    <n-form ref="formRef" :model="model" :rules="rules" class="supporter-form">
      <n-form-item path="firstname" label="Vorname">
        <n-input v-model:value="model.firstname" @keydown.enter.prevent placeholder=""/>
      </n-form-item>
      <n-form-item path="lastname" label="Nachname">
        <n-input v-model:value="model.lastname" @keydown.enter.prevent placeholder=""/>
      </n-form-item>
      <n-form-item path="email" label="E-Mail">
        <n-input v-model:value="model.email" @keydown.enter.prevent placeholder=""/>
      </n-form-item>
      <n-form-item>
        <ImagePicker @imageTaken="newImageTaken"/>
      </n-form-item>
      <n-form-item>
        <n-button @click="handleValidateClick" type="primary" style="width: 100%">
          Unterstützen
        </n-button>
      </n-form-item>
    </n-form>
  </div>
</template>


<script>
import {ref} from "vue";
import {NButton, NForm, NFormItem, NInput, useMessage} from "naive-ui";
import {createSupporter} from "@/services/supporter";
import router from "@/router";
import ImagePicker from "@/components/ImagePicker";

export default {
  name: 'ImagesView',
  setup() {
    const formRef = ref(null);
    const message = useMessage();

    const blobToBase64 = (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          resolve(reader.result);
        };
      });
    };

    return {
      formRef,
      model: ref({
        firstname: null,
        lastname: null,
        email: null,
        image: null,
      }),
      rules: {
        firstname: [{
          required: true,
          message: "Bitte geben Sie einen Vornamen ein",
          trigger: "blur"
        }],
        lastname: [{
          required: true,
          message: "Bitte geben Sie einen Nachnamen ein",
          trigger: ["input", "blur"]
        }],
        email: [{
          required: true,
          message: "Bitte geben Sie eine E-Mail ein",
          trigger: "blur"
        }],
      },
      async newImageTaken(image) {
        if (image === null) {
          formRef.value.model.image = null;
        } else {
          const b64 = await blobToBase64(image);
          formRef.value.model.image = b64.toString();
        }
      },
      handleValidateClick(e) {
        e.preventDefault();
        formRef.value?.validate((errors) => {
          if (!errors) {
            createSupporter(formRef.value.model)
              .then(() => {
                message.success("Vielen Dank, dass Sie das Projekt unterstüzen möchten");
                router.push("/");
              })
              .catch(() => message.error("Leider gab es einen Fehler bei der Eingabe Ihrer Daten"));
          } else {
            console.log(errors);
            message.error("Leider gab es einen Fehler bei der Eingabe Ihrer Daten");
          }
        });
      }
    }
  },
  components: {
    ImagePicker,
    NForm,
    NFormItem,
    NInput,
    NButton,
  }
};
</script>


<style scoped lang="scss">
.container {
  margin: 20px;
  text-align: center;
}

.supporter-form {
  max-width: 500px;
  margin: auto;
}
</style>
