import axios from "axios";

var config = {
  headers: {'Access-Control-Allow-Origin': '*'}
}

const baseUrl = "https://www.deutschesinternetmuseum.de/api/supporter/"

export function getSupporters() {
  return axios.get(baseUrl, config)
    .then(response => response.data);
}

export function createSupporter(model) {
  console.log(model);
  return axios.post(baseUrl, model, config);
}
