<template>
  <n-config-provider :theme="theme">
    <n-global-style/>
    <n-message-provider>
      <n-layout>
        <n-layout-header style="padding: 5px">
          <!--<video-background
            src="geschossbau5Webversion.mp4"
            style="max-height: 300px; height: 100vh;" overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)">-->
          <n-space justify="space-between" size="large">
            <n-menu :value="activeKey" :mode="width < 700 ? 'vertical':'horizontal'" :options="menuOptions"/>
            <!--<n-button text @click="changeTheme()" size="large" style="margin: 8px"
                      :color="theme == null ? '#000000' : '#ffffff'">
              <n-icon size="25" :component="ColorPalette"/>
            </n-button>-->
          </n-space>
          <!--</video-background>-->
        </n-layout-header>
        <n-layout-content style="margin: 20px 0px;">
          <router-view/>
        </n-layout-content>
        <n-layout-footer>
          <div class="footer-right">
            <router-link to="/impressum">Impressum</router-link>|
            <router-link to="/datenschutz">Datenschutz</router-link>
          </div>
        </n-layout-footer>
      </n-layout>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import {h, ref} from 'vue';
import {RouterLink} from "vue-router";
import {
  NIcon,
  NMenu,
  NButton,
  NConfigProvider,
  NGlobalStyle,
  NSpace,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
  darkTheme, NMessageProvider,
} from "naive-ui";
import {
  Home,
  People,
  ColorPalette, Camera, Footsteps,
} from "@vicons/ionicons5";
import VideoBackground from 'vue-responsive-video-background-player'

function renderIcon(icon) {
  return () => h(NIcon, null, {default: () => h(icon)});
}

export default {
  name: 'App',
  data() {
    return {
      width: window.innerWidth,
      menuOptions: [
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: "startpage",
              }
            },
            {default: () => "Startseite"}
          ),
          key: "go-back-home",
          icon: renderIcon(Home)
        },
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: "images",
              }
            },
            {default: () => "Fotogalerie"}
          ),
          key: "go-to-photos",
          icon: renderIcon(Camera)
        },
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: "besupporter",
              }
            },
            {default: () => "Unterstütze das Projekt"}
          ),
          key: "go-to-supporter",
          icon: renderIcon(People)
        },
        {
          label: () => h(
            RouterLink,
            {
              to: {
                name: "virtual-tour",
              }
            },
            {default: () => "Virtueller Rundgang"}
          ),
          key: "go-to-tour",
          icon: renderIcon(Footsteps)
        }
      ],
      activeKey: ref(null),
    };
  },
  methods: {
    changeColorButtonColor() {
      return this.theme == null ? '#ffffff' : '#000000';
    },
    changeTheme() {
      console.log(this.theme);
      this.theme == null ? this.theme = darkTheme : this.theme = null;
    },
  },
  setup() {
    return {
      darkTheme,
      ColorPalette,
      theme: darkTheme,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth
    })
  },
  components: {
    NMenu,
    //NIcon,
    NConfigProvider,
    //NButton,
    NGlobalStyle,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NSpace,
    NMessageProvider,
    //VideoBackground,
  }
};
</script>

<style lang="scss">
@import 'animate.css';

.title {
  font-size: 50px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  border: 1px white;
  text-shadow: 0px 0px 10px black;
}

.pre-title {
  font-size: 30px;
}

.footer-right {
  float: right;
  margin: 15px;

  a {
    padding: 15px;
    text-decoration: none;
    color: white;
  }
}
</style>
