<template>
<div class="normal-text-container">
  <h1>Datenschutzerklärung</h1>

  <h2>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</h2>
  <p>
    <strong>Hans Decker</strong> <br/>
    c/o Dekanat Informatik <br/>
    Otto-Hahn-Str. 14 <br/>
    D-44221 Dortmund
  </p>
  <p>
    Telefon: +49 231 755-2208 <br/>
    E-Mail: hans.decker@udo.edu
  </p>

  <h2>Erfassung allgemeiner Informationen</h2>
  <p>Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet Service Providers und Ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen. Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Anonyme Informationen dieser Art werden von uns statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>

  <h2>Registrierung als Unterstützer auf unserer Webseite</h2>
  <p>Bei der Registrierung als Unterstützer der Webseite werden einige personenbezogene Daten erhoben, wie Name und Kontaktdaten (bspw. Telefonnummer und E-Mail-Adresse). Sind Sie bei uns registriert, werden Sie auf unserer Seite als Unterstützer aufgeführt. Selbstverständlich erteilen wir Ihnen jederzeit Auskunft über die von uns über Sie gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.</p>
  <h3>SSL-Verschlüsselung</h3>
  <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>

  <h2>Löschung bzw. Sperrung der Daten</h2>
  <p>Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>

  <h2>Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</h2>
  <p>Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen Daten. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die Kontaktdaten finden Sie ganz unten.
    Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie können auch die Löschung der Daten verlangen, soweit keine gesetzliche Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung besteht, sperren wir Ihre Daten auf Wunsch.
    Sie können Änderungen oder den Widerruf einer Einwilligung durch entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.</p>
</div>
</template>

<script>
export default {
  name: "DataPrivacyView"
}
</script>

<style scoped>
.normal-text-container {
  max-width: 700px;
  margin: 5px auto;
}
</style>
