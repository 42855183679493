<template>
  <div class="startpage">

    <div class="normal-text-container">
      <h3 class="title pre-title">Hier entsteht das ...</h3>
      <h1 class="title">Deutsche Internet Museum</h1>

      <p>Das Internet, ist allgegenwärtig, wir alle nutzen es täglich bei der Arbeit und in der Freizeit, auf dem Handy, auf dem Laptop oder auf dem PC. Wir kommunizieren damit und wir informieren uns, das Internet ist unser ständiger Begleiter.</p>

      <p>Aber wie kam das Internet nach Deutschland? Was hat das mit Dortmund zu tun?</p>

      <p>Erforsche die Geschichte des deutschen Internets, indem du Zeitzeugen, Dokumente und Medien entdeckst, die in den ehemaligen Räumen der Informatik-Rechner Betriebsgruppe der Technischen Universität Dortmund verteilt sind. Folge den weißen Pfeilen ...</p>

      <n-space justify="space-around" size="large" class="button-group">
        <n-button @click="$router.push('vt')" type="primary">Zur virtuellen Tour</n-button>
        <n-button @click="$router.push('supporters')">Alle Unterstützer</n-button>
      </n-space>
      <n-space justify="space-around" size="large" class="button-group">
        <n-button @click="$router.push('images')">Fotogalerie</n-button>
        <n-button @click="$router.push('besupporter')" type="primary">Unterstüze das Projekt</n-button>
      </n-space>

      <img
        src="outside/DJI_0056.jpg"
        class="centering"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {NButton, NSpace} from "naive-ui";

export default {
  name: 'StartpageView',
  components: {
    NSpace,
    NButton,
  },
};
</script>

<style scoped lang="scss">

.normal-text-container {
  max-width: 700px;
  margin: 5px auto;
}

img {
  max-width: 700px;
}

.centering {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.button-group {
  margin: 15px;
}
</style>
