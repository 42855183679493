<template>
  <SupporterMap presenter-style/>
</template>

<script>
import SupporterMap from "@/components/SupporterMap";
export default {
  name: "SupporterView.vue",
  components: {SupporterMap}
}
</script>

<style scoped>

</style>
