import { createRouter, createWebHistory } from 'vue-router';
import StartpageView from '../views/StartpageView.vue';
import ImagesView from "@/views/ImagesView";
import BeSupporter from "@/views/BeSupporter";
import SupporterView from "@/views/SupporterView";
import ImpressumView from "@/views/ImpressumView";
import DataPrivacyView from "@/views/DataPrivacyView";

const routes = [
  {
    path: '/',
    name: 'startpage',
    component: StartpageView,
  },
  {
    path: '/besupporter',
    name: 'besupporter',
    component: BeSupporter,
  },
  {
    path: '/supporters',
    name: 'supporters',
    component: SupporterView,
  },
  {
    path: '/images',
    name: 'images',
    component: ImagesView,
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumView,
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DataPrivacyView,
  },
  {
    path: '/vt',
    name: 'virtual-tour',
    component: ImagesView,
    beforeEnter(to, from, next) {
      window.location.href = "https://www.deutschesinternetmuseum.de/virtual-tour/index.htm"
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
