<template>
  <camera :resolution="{width: 700, height: 700 }" ref="camera" autoplay>
    <div class="spacer"></div>
    <n-space justify="center">
      <n-button circle type="primary" @click="switchCamera()" :disabled="device === undefined">
        <n-icon size="25"><camera-reverse /></n-icon>
      </n-button>
      <n-button circle type="primary" @click="takePhoto()">
        <n-icon size="25"><camera-icon /></n-icon>
      </n-button>
      <n-button circle type="primary" @click="undoPhoto()" :disabled="image === undefined">
        <n-icon size="25"><arrow-undo /></n-icon>
      </n-button>
    </n-space>
  </camera>
</template>

<script>
import { Camera as CameraIcon, ArrowUndo, CameraReverse } from "@vicons/ionicons5";
import Camera from "simple-vue-camera";
import {onMounted, ref} from "vue";
import {NButton, NIcon, NSpace } from "naive-ui";
export default {
  name: "ImagePicker",
  emits: ["imageTaken"],
  setup(props, { emit }) {
    const camera = ref(null);
    const image = ref(undefined);
    const device = ref(undefined);
    const devices = ref([]);

    onMounted(() => {
      camera.value.devices(["videoinput"]).then(d => {
        if (d.length <= 1) {
          device.value = undefined;
        } else {
          device.value = 0;
        }
        devices.value = d;
      });
    });

    const takePhoto = async () => {
      await camera.value.pause();
      const blob = await camera.value.snapshot({
        width: 700, height: 700
      }, "image/jpg", 0.5);

      image.value = blob;
      emit('imageTaken', image.value);
    }

    const undoPhoto = async () => {
      await camera.value.resume();
      image.value = undefined;
      emit('imageTaken', null);
    }

    const switchCamera = async () => {
      if (device.value === undefined) {return;}
      device.value = device.value + 1 % devices.value.length;
      camera.value.changeCamera(devices[device.value].value.deviceId);
    }

    return {
      camera,
      image,
      device,
      devices,
      undoPhoto,
      takePhoto,
      switchCamera,
    };
  },
  mounted() {
    /*this.devices = this.camera.value.devices(["videoinput"]).map(d => {
      return {
        label: d.deviceId,
        value: d.deviceId,
      }
    });
    console.log(this.devices);*/
  },
  components: {
    Camera,
    NButton,
    CameraIcon,
    NIcon,
    NSpace,
    ArrowUndo,
    CameraReverse,
  }
}
</script>

<style scoped>
  .spacer {
    height: 90%;
  }
</style>
